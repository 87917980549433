import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Routes, Route } from 'react-router-dom';
import { checkAuthenticationStatus } from '../../store/userSlice';
import TheFeed from '../TheFeed/TheFeed';
import InsideReaderPortal from '../InsideReaderPortal/InsideReaderPortal';
import Library from '../Library/Library';
import Find from '../Find/Find';
import ReadersFooter from '../../components/ReaderFooter/ReaderFooter';

function ReaderPortal() {
  const dispatch = useDispatch();
  const isAuthenticated = useSelector((state) => state.user.isAuthenticated);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    dispatch(checkAuthenticationStatus()).then(() => setIsLoading(false));
  }, [dispatch]);

  if (isLoading) {
    return <div>Loading...</div>;
  }

  return (
    <div>
      <Routes>
        {!isAuthenticated ? (
          <>
            <Route path="/" element={<TheFeed />} />
            <Route path="find" element={<Find />} />
          </>
        ) : (
          <Route path="/" element={<InsideReaderPortal />}>
            <Route index element={<TheFeed />} />
            <Route path="library" element={<Library />} />
            <Route path="find" element={<Find />} />
          </Route>
        )}
      </Routes>
      {/* Include ReadersFooter outside conditional */}
      <ReadersFooter isAuthenticated={isAuthenticated} />
    </div>
  );
}

export default ReaderPortal;
