import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import './TaggedBooksEditFeed.css'; // Make sure to create this CSS file
import closeIcon from '../../assets/images/circle-xmark-regular.svg'; // Ensure this path is correct
import {setTaggedBooks} from '../../store/editingSlice';

const TaggedBooksFeed = () => {
    const dispatch = useDispatch();
    const feedItems = useSelector(state => state.editing.taggedBooks);

    console.log('feeditems',feedItems);

    const removeBook = (indexToRemove) => {
        // Confirm removal
        if (window.confirm("Are you sure you want to remove this book?")) {
          // Remove the book and update the state
          const updatedBooks = feedItems.filter((_, index) => index !== indexToRemove);
          dispatch(setTaggedBooks(updatedBooks));
        }
      };

    if (feedItems.length === 0) {
      return (
        <div className="feedContainer">
          <h2 className="feedName">Advertised books:</h2>
          <p className="emptyFeedMessage">You have not tagged any books.</p>
        </div>
      );
    }

    const placeholders = Array(4 - feedItems.length).fill(null); 

    return (
        <div className="feedContainer">
            <h2 className="feedName">Advertised books:</h2>
            <div className="feedItemsContainer">
                {feedItems.slice(0, 4).map((item, index) => (
                    <div className="feedItem" key={index}>
                        <img 
                            className="itemImage" 
                            src={item.image_link} 
                            alt={item.title}
                        />
                        <button className="removeBookButton" onClick={() => removeBook(index)}>
                            <img src={closeIcon} alt="Remove book" />
                        </button>
                    </div>
                ))}
                {placeholders.map((_, index) => <div className="feedItem" key={index + feedItems.length}></div>)}  
            </div>
        </div>
    );
};

export default TaggedBooksFeed;
