import React from 'react';
import { useSelector } from 'react-redux';
import './DescriptiveEditDetails.css'; // Make sure to create and import your CSS file

const DescriptiveData = () => {
    const style = useSelector((state) => state.editing.style);
    const genre = useSelector((state) => state.editing.genre);
    const isStyleBlank = style === 'blank';
    const isGenreBlank = genre === 'blank';

    const styleText = isStyleBlank ? 'Style is blank' : `Style: ${style}`;
    const genreText = isGenreBlank ? 'Blank is genre' : `Genre: ${genre}`;

    return (
        <div className="descriptive-container">
            <div className="descriptive-title">Descriptive data of piece:</div>
            <div className="descriptive-row">
                <div className={isStyleBlank ? 'descriptive-blank' : 'descriptive-style'}>{styleText}</div>
                <div className={isGenreBlank ? 'descriptive-blank' : 'descriptive-genre'}>{genreText}</div>
            </div>
        </div>
    );
};

export default DescriptiveData;
