import React, { useState, useEffect } from 'react';
import Header from '../../components/Header/Header';
import './Find.css';
import { apiInstance } from '../../utils/axiosInstance';
import AuthorSummarySearch from '../../components/AuthorSummarySearch/AuthorSummarySearch';
import { Helmet } from 'react-helmet';

function Find() {
  const [query, setQuery] = useState('');
  const [responseMessage, setResponseMessage] = useState('');
  const [results, setResults] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [randomWritersFetched, setRandomWritersFetched] = useState(false);

  useEffect(() => {
    if (!randomWritersFetched) {
      const fetchRandomWriters = async () => {
        try {
          const response = await apiInstance.get('/tag-search/random-writers');
          setResults(response.data);
        } catch (error) {
          console.error('Error fetching random writers:', error);
        } finally {
          setRandomWritersFetched(true);
        }
      };
      fetchRandomWriters();
    }
  }, [randomWritersFetched]);

  const handleSearch = async () => {
    if (!query.trim()) {
      setResponseMessage('Please enter a search term.');
      return;
    }

    setIsLoading(true);
    setResponseMessage('');

    try {
      const response = await apiInstance.get('/tag-search/writers', {
        params: { query },
      });

      if (response.data.length > 0) {
        setResults(response.data);
        setResponseMessage(''); // Clear the response message when results are found
      } else {
        setResults([]);
        setResponseMessage('No results found');
      }
    } catch (error) {
      console.error('Search error:', error);
      setResponseMessage('An error occurred while searching. Please try again.');
    } finally {
      setIsLoading(false);
    }
  };

  const handleKeyPress = (e) => {
    if (e.key === 'Enter') {
      handleSearch();
    }
  };

  return (
    <div className="find">
      <Helmet>
        <title>Find | ShorterStories</title>
        <meta name="description" content="Discover a curated feed of short stories, flash fiction, and bite-sized literature from talented writers on ShorterStories. Explore diverse genres and styles in our unique writing platform." />
        <meta name="keywords" content="short stories, flash fiction, bite-sized literature, writer platform, ShorterStories, The Feed" />
        <meta property="og:title" content="The Feed | ShorterStories" />
        <meta property="og:description" content="Explore a curated feed of short stories and flash fiction from diverse writers on ShorterStories. Discover new voices and exciting narratives in our unique writing platform." />
        <meta property="og:type" content="website" />
        <meta property="og:url" content="https://www.shorterstories.xyz/thefeed" />
        <link rel="canonical" href="https://www.shorterstories.xyz/thefeed" />
      </Helmet>
      <Header />
      <div className="find__search-container">
        <input
          type="text"
          className="find__search-bar"
          placeholder="Search for a writer..."
          value={query}
          onChange={(e) => setQuery(e.target.value)}
          onKeyPress={handleKeyPress}
        />
        <button
          className={`find__submit-button ${isLoading ? 'find__submit-button--disabled' : ''}`}
          onClick={handleSearch}
          disabled={isLoading}
        >
          {isLoading ? 'Searching...' : 'Search'}
        </button>
      </div>
      <div className="find__response">
        {responseMessage && <p className="find__message">{responseMessage}</p>}
        <div className="find__results-list">
          {results.map((author, index) => (
            <AuthorSummarySearch key={index} author={author} />
          ))}
        </div>
      </div>
    </div>
  );
}

export default Find;
 