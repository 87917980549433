// RichTextEditor.js
import React, { useCallback, useMemo, useState, useEffect } from 'react';
import { Editor, Transforms, createEditor , Text} from 'slate';
import { Slate, Editable, withReact } from 'slate-react';
import { Toolbar } from '../Toolbar/Toolbar';
import { MarkButton } from '../MarkButton/MarkButton';
import { BlockButton } from '../BlockButton/BlockButton';
import isHotkey from 'is-hotkey';
import { withHistory } from 'slate-history';
import { useSelector, useDispatch } from 'react-redux';
import { setPiece } from '../../store/editingSlice';
import DOMPurify from 'dompurify'; 


import './PieceEditEditor.css';

const HOTKEYS = {
  'mod+b': 'bold',
  'mod+i': 'italic',
  'mod+u': 'underline',
};

const RichTextEditor = ({ clearTrigger,setClearTrigger } ) => {
    const dispatch = useDispatch();
    const renderElement = useCallback(props => <Element {...props} />, [])
    const renderLeaf = useCallback(props => <Leaf {...props} />, [])
    const storedHtml = useSelector((state) => state.editing.piece);
  const editor = useMemo(() => withHistory(withReact(createEditor())), []);




  
  useEffect(() => {
    // Check if the document is not empty
    if (editor.children.length > 0) {
      const firstNode = editor.children[0]; // Get the first top-level node
      let path = [0]; // Initialize path with the first node
  
      // Function to recursively find the path to the first text node
      const findPathToTextNode = (node, currentPath) => {
        if (node.text !== undefined) {
          // Base case: node is a text node
          return currentPath;
        } else if (node.children && node.children.length > 0) {
          // Recursive case: dive deeper
          return findPathToTextNode(node.children[0], currentPath.concat(0));
        }
        // Fallback case: return the current path if it's not clear where to go next
        return currentPath;
      };
  
      // Determine the correct path to the first text node
      path = findPathToTextNode(firstNode, path);
  
      // Attempt to set the selection at the determined path
      Transforms.select(editor, {
        anchor: { path, offset: 0 },
        focus: { path, offset: 0 },
      });
    }
  }, [editor]);
  
  

  const toggleMark = (editor, format) => {
    const isActive = Editor.marks(editor)?.[format];
    if (isActive) {
      Editor.removeMark(editor, format);
    } else {
      Editor.addMark(editor, format, true);
    }
  };

    const deserializeChildren = (element) => {


      const children = Array.from(element.childNodes)
        .map((node) => {
          if (node.nodeType === 3) {
            // If it's a text node, return the text

            return { text: node.textContent };
          } else if (node.nodeType === 1) {
            // If it's an element node, use deserializeElement to handle it

            return deserializeElement(node);
          }
          // Otherwise, return null and filter it out in the next step
          return null;
        })
        .filter(Boolean); // This will remove any `null` values resulting from nodes we don't handle

      return children.length ? children : [{ text: '' }]; // Ensure there's always at least one text node
    };
    
    
    
    const deserializeElement = (element) => {

      if (element.tagName === 'BODY' || element.tagName === 'DIV') {
        const children = deserializeChildren(element);

        return children;
      }

      const style = element.getAttribute('style');
      const alignment = style ? style.match(/text-align:\s*(left|right|center|justify);?/) : null;
      const alignValue = alignment ? alignment[1] : null;


    
      switch (element.tagName) {
        case 'BLOCKQUOTE':
          const blockQuote = { type: 'block-quote', children: deserializeChildren(element) };
          if (alignValue) {
            blockQuote.align = alignValue; // Add alignment to the block-quote if it exists
          }
          return blockQuote;
        case 'P':
          const paragraph = { type: 'paragraph', children: deserializeChildren(element) };
          if (alignValue) {
            paragraph.align = alignValue; // Add alignment to the paragraph if it exists
          }
          return paragraph;
        case 'UL':
          const bulletedList = { type: 'bulleted-list', children: deserializeChildren(element) };
          if (alignValue) {
            bulletedList.align = alignValue; // Add alignment to the bulleted list if it exists
          }
          return bulletedList;
        case 'OL':
          const numberedList = { type: 'numbered-list', children: deserializeChildren(element) };
          if (alignValue) {
            numberedList.align = alignValue; // Add alignment to the numbered list if it exists
          }
          return numberedList;
          case 'LI':
            const listItem = {
              type: 'list-item',
              children: deserializeChildren(element).map(child => {
                if (typeof child === 'string') {
                  return { text: child };
                }
                return child;
              }),
            };
            if (alignValue) {
              listItem.align = alignValue; // Add alignment to the list item if it exists
            }
            return listItem;
        case 'STRONG':
          return { text: element.textContent, bold: true };
        case 'EM':
          return { text: element.textContent, italic: true };
        case 'U':
          return { text: element.textContent, underline: true };
        default:
          if (element.childNodes.length > 0) {

            return deserializeChildren(element);
          }

          return { text: element.textContent };
      }
    };

    


  const initialValue = useMemo(() => {
    if (!storedHtml) {
                  return [{
                type: 'paragraph',
                align: 'justify', // Default alignment to justify
                children: [{ text: '' }]
            }];
    }
  
    const document = new DOMParser().parseFromString(storedHtml, 'text/html');
    const deserializedContent = deserializeElement(document.body);
    // console.log('Deserialized content:', deserializedContent);

  
    if (!deserializedContent || deserializedContent.length === 0) {
      return [{ type: 'paragraph', children: [{ text: '' }] }];
    }
  
    return Array.isArray(deserializedContent) ? deserializedContent : [deserializedContent];
  }, [storedHtml]);
  


  useEffect(() => {
    if (clearTrigger) {
      // Define a clean initial state
      const emptyState = [
        {
          type: 'paragraph',
          align: 'justify', // Ensure default alignment is justify
          children: [{ text: '' }]
        },
      ];
  
      // Directly set the editor's content to the clean state
      Editor.withoutNormalizing(editor, () => {
        editor.children = emptyState;
        Transforms.deselect(editor);
        // Reset the selection to the start of the document
        Transforms.select(editor, {
          anchor: { path: [0, 0], offset: 0 },
          focus: { path: [0, 0], offset: 0 },
        });
      });
  
      setClearTrigger(false); // Reset the clear trigger
    }
  }, [clearTrigger, editor, setClearTrigger]);
  
  
  



  const handleEditorChange = (newValue) => {
    // console.log('Current editor value:', editor.children);
    
    const html = Array.isArray(newValue) ? newValue.map(serializeNode).join('') : '';
    // console.log('HTML:', html);
    dispatch(setPiece(html));
  };  

  const handleKeyDown = (event) => {
    for (const hotkey in HOTKEYS) {
      if (isHotkey(hotkey, event)) {
        event.preventDefault();
        const mark = HOTKEYS[hotkey];
        toggleMark(editor, mark);
      }
    }

  };

  // Utils to handle HTML serialization and deserialization
  const serializeNode = (node) => {

    if (Editor.isEditor(node)) {

      return node.children.map(serializeNode).join('');
    }
  
    if (Text.isText(node)) {
      let string = DOMPurify.sanitize(node.text); 
      if (node.bold) {
        string = `<strong>${string}</strong>`;
      }
      if (node.italic) {
        string = `<em>${string}</em>`;
      }
      if (node.underline) {
        string = `<u>${string}</u>`;
      }

      return string;
    }
  
    const children = node.children.map((child) => {

      return serializeNode(child);
    }).join('');

    const alignStyle = node.align ? ` style="text-align:${node.align};"` : '';
  
    switch (node.type) {
      case 'paragraph':
        return `<p${alignStyle}>${children}</p>`;
      case 'block-quote':
        return `<blockquote${alignStyle}>${children}</blockquote>`;
      case 'bulleted-list':
        return `<ul${alignStyle}>${children}</ul>`;
      case 'numbered-list':
        return `<ol${alignStyle}>${children}</ol>`;
      case 'list-item':
        return `<li${alignStyle}>${children}</li>`;
      default:
        return children;
    }
  };
// Define a deserialization function






// When you initialize the editor, you should deserialize the stored HTML content




const sanitizePaste = (event) => {
  event.preventDefault();

  // Get plain text from clipboard
  const pastedText = event.clipboardData.getData('text/plain');

  // Split the pasted text into lines
  const lines = pastedText.split(/\r\n|\r|\n/);

  // Create a list of Slate nodes for each line
  const paragraphNodes = lines.map(line => ({
      type: 'paragraph',
      align: 'justify',
      children: [{ text: line }]
  }));

  // Insert the list of nodes as a fragment at the current selection
  Editor.withoutNormalizing(editor, () => {
      // Check if there is an existing selection
      if (!editor.selection) {
          return;
      }

      // Insert the new paragraph nodes
      Transforms.insertFragment(editor, paragraphNodes);
  });
};


  





  return (
    <Slate editor={editor} initialValue={initialValue} onChange={handleEditorChange}>
      <div className="editor-title">Piece editor</div>   
      <Toolbar className="toolbar">
        <MarkButton format="bold" icon="format_bold" />
        <MarkButton format="italic" icon="format_italic" />
        <MarkButton format="underline" icon="format_underlined" />
        <BlockButton format="block-quote" icon="format_quote" />
        <BlockButton format="numbered-list" icon="format_list_numbered" />
        <BlockButton format="bulleted-list" icon="format_list_bulleted" />
        <BlockButton format="left" icon="format_align_left" />
        <BlockButton format="center" icon="format_align_center" />
        <BlockButton format="right" icon="format_align_right" />
        <BlockButton format="justify" icon="format_align_justify" />
      </Toolbar>
      <Editable
        className="editable"
        onPaste={sanitizePaste}
        // autoFocus
        renderElement={renderElement}
        renderLeaf={renderLeaf}
        placeholder="Write your piece here..."
        onKeyDown={handleKeyDown}

      />
    </Slate>
  );
};

const Element = ({ attributes, children, element }) => {

  const style = { textAlign: element.align };

  switch (element.type) {
    case 'block-quote':
      return (
        <blockquote style={style} {...attributes} className="block-quote">
          {children}
        </blockquote>
      );
    case 'bulleted-list':
      return (
        <ul style={style} {...attributes}>
          {children}
        </ul>
      );
    case 'numbered-list':
      return (
        <ol style={style} {...attributes}>
          {children}
        </ol>
      );
      case 'list-item':

        return (
          <li style={style} {...attributes}>
            {children}
          </li>
        );
    default:
      return (
        <p style={style} {...attributes}>
          {children}
        </p>
      );
  }
};

const Leaf = ({ attributes, children, leaf }) => {
  if (leaf.bold) {
    children = <strong>{children}</strong>;
  }

  if (leaf.italic) {
    children = <em>{children}</em>;
  }

  if (leaf.underline) {
    children = <u>{children}</u>;
  }

  return <span {...attributes}>{children}</span>;
};


export default RichTextEditor;
