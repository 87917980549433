import React, { useState, useMemo } from 'react';
import { useDispatch, useSelector} from 'react-redux';
import { useNavigate } from 'react-router-dom';
import Header from '../../components/HeaderEdit/HeaderEdit';
import { setCurrentStage, setTitle, setSubtitle,  setDescription, resetEditingProcess } from '../../store/editingSlice';
import './WritersEditWriting.css';

import RichTextEditor from '../../components/PieceEditEditor/PieceEditEditor';

function WritersPublishWriting() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [clearTrigger, setClearTrigger] = useState(false);



  const title = useSelector((state) => state.editing.title);
  const description = useSelector((state) => state.editing.description);
  const subtitle = useSelector((state) => state.editing.subtitle);
  const isSerial = useSelector((state) => state.editing.isSerial);
  const serialPart = useSelector((state) => state.editing.serialPartNumber);

  console.log('serialPart', serialPart);

  const isTitleLocked = isSerial && serialPart > 1;
  

  // Function to navigate to the next stage
  const navigateToNextStages = () => {
    console.log('Navigating to next stage');
    dispatch(setCurrentStage('details'));
    navigate(`/writers/edit/details`);

    // Placeholder for navigation, ensure you have your routing set up to navigate
  };

  const clearAllContent = () => {
    if (window.confirm('Are you sure you want to discard all text? This cannot be undone.')) {
      dispatch(setTitle(''));
      dispatch(setDescription(''));
      setClearTrigger(true); 

    }
  };
  const handleDiscardAndRestart = () => {
    const confirmRestart = window.confirm("Are you sure you want to discard your edits?");
    if (confirmRestart) {
      dispatch(resetEditingProcess());
      navigate('/writers/edit/selection');
    }
  };

  const numberToWord = (num) => {
    const words = ['one', 'two', 'three', 'four', 'five', 'six', 'seven', 'eight', 'nine'];
    return num >= 1 && num <= 9 ? words[num - 1] : num.toString();
  };



  return (
    <div>
      <Header />
      <div className="writing-area">
        <div className="title-description-container">
          <div className="title-description-header">Title and description:</div>
          {isTitleLocked ? (
              <div className="locked-title" style={{ textAlign: 'left', marginTop: '10px', width: '93%', marginBottom: '10px',}}>
                <span style={{ fontWeight: 'bold' }}>Title: </span>
                <span>{title}</span>
                <span style={{ fontWeight: 'normal' }}> - part {numberToWord(serialPart)}</span>
              </div>
          ) : (
            <input
              className="title-input"
              placeholder="Title"
              value={title}
              onChange={(e) => {
                if (e.target.value.length <= 120) {
                  dispatch(setTitle(e.target.value));
                }
              }}
            />
          )}
          {/* {isSerial && (
            <input
              className="title-input"
              placeholder="Subtitle (very optional)"
              value={subtitle}
              onChange={(e) => {
                if (e.target.value.length <= 120) {
                  dispatch(setSubtitle(e.target.value));
                }
              }}
            />
          )} */}
          <textarea
            className="description-input"
            placeholder="Description"
            value={description}
            onChange={(e) => {
              const words = e.target.value.split(/\s+/); // Split the input by any whitespace to count words
              if (words.length <= 100 || e.target.value === "") { // Allow empty string to enable clearing the field
                dispatch(setDescription(e.target.value));
              } else {
                // If the input exceeds 100 words, prevent additional input while maintaining the last valid value
                // This optionally trims down to the first 100 words:
                const trimmed = words.slice(0, 100).join(' ');
                dispatch(setDescription(trimmed));
              }
            }}
          />
        </div>
        <div className="piece-editor-container">
          <RichTextEditor clearTrigger={clearTrigger} setClearTrigger={setClearTrigger}/>

        </div>
        <div className="buttons-container">
          <button className="infosButton first-button" onClick={navigateToNextStages} style={{ fontWeight: 'bold' }}>
            Use text and continue to next step →
          </button>
          <button className="infosButton second-button" onClick={clearAllContent}>
            Discard text
          </button>
        </div>
        <div className="button-separatorest"></div>
        <button className="infosssButton" onClick={handleDiscardAndRestart}>
            Discard editing
          </button>
      </div>
    </div>
  );
}

export default WritersPublishWriting;
