// WritersPublishImage.js
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { setTaggedBooks, setGenre, setStyle, setCurrentStage, resetEditingProcess } from '../../store/editingSlice';
import DescriptiveDetails from '../../components/DescriptiveEditDetails/DescriptiveEditDetails';

import { showGenericModal } from '../../store/modalSlice';
import { clearSelections } from '../../store/taggingEditSlice';
import TaggedBooksFeed from '../../components/TaggedBooksEditFeed/TaggedBooksEditFeed';


import HeaderEdit from '../../components/HeaderEdit/HeaderEdit';
import './WritersEditDetails.css';


function WritersPublishDetails() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  // Ensure to handle the case where `currentPhoto` is null or undefined initially

  const buttonText = "Use image and continue to next step \u2192"; 
  
  const selectedStyle = useSelector((state) => state.editing.style);
  const selectedGenre = useSelector((state) => state.editing.genre);
  console.log(selectedGenre);
  const taggedBooks = useSelector((state) => state.editing.taggedBooks);
  const title = useSelector((state) => state.editing.title);
  const description = useSelector((state) => state.editing.description);
  const piece = useSelector((state) => state.editing.piece);


  const handleEditBooks = () => {
    dispatch(clearSelections());
    dispatch(showGenericModal({
      modalType: 'TAG_BOOK_EDIT',
      modalProps: { /* properties you want to pass to the modal */ }
    }));
  };

  const handlePreview = () => {
    const errors = [];
    console.log('freaking');
    const navigateToPreview = "../../../writers/edit-piece";
    console.log('wowser');
  
    // Function to remove HTML tags and trim the remaining text
    const stripHtml = html => {
      const tmp = document.createElement("DIV");
      tmp.innerHTML = html;
      return tmp.textContent || tmp.innerText || "";
    };
  
    // Check for title and description
    if (!title.trim() || !description.trim()) {
      errors.push("You need to include both a title and a description.");
    }
  
    // Check for piece content
    if (!stripHtml(piece).trim()) {
      errors.push("The piece needs to be at least 1 word long.");
    }
  
    // Check for genre and style 
    if (selectedGenre === 'blank' || selectedStyle === 'blank') {
      errors.push("You need to set both genre and style.");
    }
  
    // Construct the alert message based on errors
    let alertMessage = "";
    if (errors.length === 1) {
      alertMessage = errors[0];
    } else if (errors.length > 1) {
      alertMessage = "A few things:\n" + errors.map(error => `- ${error}`).join("\n");
    }
  
    // Append the navigation instruction if necessary
    if (errors.length > 0 && (!title.trim() || !description.trim() || !stripHtml(piece).trim())) {
      alertMessage += "\n\nYou can navigate back to the writing page using the header.";
    }
  
    // Show the alert or navigate
    if (errors.length > 0) {
      alert(alertMessage);
    } else {
      navigate(navigateToPreview);
      console.log('whatchask');
    }
  };
  

  const handleEditPress = () => {
    // Validate and navigate to preview

      dispatch(showGenericModal({
        modalType: 'EDIT_DETAILS_EDIT',
        modalProps: { /* properties you want to pass to the modal */ }
      }));

  };

  const handleDiscardAndRestart = () => {
    const confirmRestart = window.confirm("Are you sure you want to discard your edits?");
    if (confirmRestart) {
      dispatch(resetEditingProcess());
      navigate('/writers/edit/selection');
    }
  };
  




  return (
    <div className="details-modal">
      <HeaderEdit />
      <div className="tag-details-boxes">
        <div className="tag-details-title">Advertising and details:</div>
        <DescriptiveDetails />
        <button onClick={handleEditPress} className="infosButton-details">
          Edit genre and style
        </button>
        <div className="details-dividers"/>
        <TaggedBooksFeed />
        <button 
          onClick={handleEditBooks} 
          className={`infosButton-detailes ${taggedBooks.length >= 4 ? 'disabled' : ''}`}
          disabled={taggedBooks.length >= 4} // This disables the button when there are 4 or more tagged books
        >
          Tag book
        </button>
        <div className="details-dividers"/>
        <button onClick={handlePreview} className="infosButton-details-preview" style={{ fontWeight: 'bold' }}>
          Preview piece
        </button>
        <div className="details-dividers"/>
        <button onClick={handleDiscardAndRestart} className="infosButton-details-previews">
          Discard editing
        </button>
      </div>
    </div>
  );
}

export default WritersPublishDetails;
