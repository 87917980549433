// WritersPublishImage.js
import React, { useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { setCurrentPhoto, setCurrentStage, resetPublishingProcess } from '../../store/publishingSlice';
import defaultImage from '../../assets/images/birdie.jpeg';
import HeaderPublish from '../../components/HeaderPublish/HeaderPublish';
import { apiInstance } from '../../utils/axiosInstance';
import { fetchAuthSession } from 'aws-amplify/auth';
import './WritersPublishImage.css';

function WritersPublishImage() {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const currentPhoto = useSelector(state => state.publishing.currentPhoto) || defaultImage;
  const fileInputRef = useRef(null);
  const [isDefault, setIsDefault] = useState(currentPhoto === defaultImage);
  const [isUploading, setIsUploading] = useState(false); 

  const buttonText = "Use image and continue to next step →"; 
  const otherButtonText = "Discard and start a new"; 

  const handleImageUploadClick = () => {
    fileInputRef.current.click();
  };

  const handleImageUpload = async (event) => {
    const session = await fetchAuthSession();
    const jwtToken = session.tokens.idToken.toString();
    const file = event.target.files[0];
    if (file) {
      setIsUploading(true);
      try {
        // Step 1: Request a presigned URL from the backend
        const formData = new FormData();
        formData.append("content_type", file.type);
        formData.append("file_size", file.size);
        formData.append("original_filename", file.name);
  
        const response = await apiInstance.post('/pieces/generate_presigned_url', formData, {
          headers: {
            Authorization: `Bearer ${jwtToken}`, // Add JWT token here
          },
        });
  
        if (response.status !== 200) { // Check status explicitly
          throw new Error('Failed to fetch presigned URL');
        }
  
        const { presigned_url, file_key } = response.data;

        // Step 2: Upload the file to S3 using the presigned URL
        await fetch(presigned_url, {
          method: 'PUT',
          body: file,
          headers: {
            'Content-Type': file.type,
          },
        });

        console.log('pre',presigned_url);
        console.log('file',file_key);

        const S3_BUCKET_URL = "https://shrtrstrz-coverart-dev.s3.amazonaws.com/";
        const fullFileUrl = `${S3_BUCKET_URL}${file_key}`;

  
        // Dispatch the file key for backend processing
        dispatch(setCurrentPhoto(fullFileUrl)); // File key will be used later for further processing
        setIsDefault(false);
      } catch (error) {
        console.error('Error uploading image to S3:', error);
      } finally {
        setIsUploading(false); // Hide spinner
      }
    }
  };

  const discardImage = () => {
    dispatch(setCurrentPhoto(defaultImage));
    setIsDefault(true);
  };

  const navigateToNextStage = () => {
    dispatch(setCurrentStage('writing'));
    navigate(`/writers/publish/writing`);
  };

  const handleImageLoad = (e) => {
    const img = e.target;
    if (img.naturalWidth < img.naturalHeight) {
        img.style.width = '100%';
        img.style.height = `${img.offsetWidth}px`;
        img.style.objectFit = 'cover';
    } else {
        img.style.width = '100%';
        img.style.height = 'auto';
    }
  };

  const handleDiscardAndRestart = () => {
    const confirmRestart = window.confirm("Are you sure you want to discard your progress and start again?");
    if (confirmRestart) {
      dispatch(resetPublishingProcess());
      navigate('/writers/publish/selection');
    }
  };

  return (
    <div className="modalesque">
      <HeaderPublish />
      <div className="contentBoxes">
        <div className="titleContainerey">
          <p className="modalTitley">Upload some cover art:</p>
        </div>
        <div>
          {isUploading ? (
            // Show the overlay spinner during upload
            <div className="overlay-spinner">
              <div className="spinner"></div>
              <p>Uploading your image, please wait...</p>
            </div>
          ) : (
            // Show the image preview when not uploading
            <img 
              src={currentPhoto} 
              alt="Cover Art" 
              className="image-previews" 
              onLoad={handleImageLoad} 
            />
          )}
        </div>
        <div className="fileUploadContainer">
          <input type="file" ref={fileInputRef} onChange={handleImageUpload} className="fileInput" accept="image/*" style={{ display: 'none' }} />
          {!isDefault && <div className="buttonRow">
            <button className="infosButton" onClick={discardImage}>Discard image</button>
            <button className="infosButton" onClick={handleImageUploadClick}>Upload new cover image</button>
          </div>}
          {isDefault && <button className="infosButton" onClick={handleImageUploadClick}>Upload cover image</button>}
        </div>
        <button onClick={navigateToNextStage} className="infosButton" style={{ fontWeight: 'bold' }}>{buttonText}</button>
        <div className="button-separatores"></div>
        <button onClick={handleDiscardAndRestart} className="infosButton">{otherButtonText}</button>
      </div>
    </div>
  );
}

export default WritersPublishImage;
