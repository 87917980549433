import React, { useEffect } from 'react';
import { Routes, Route, useNavigate } from 'react-router-dom';
import WriterEditImage from '../WritersEditImage/WritersEditImage';
import WriterEditWriting from '../WritersEditWriting/WritersEditWriting';
import WriterEditDetails from '../WritersEditDetails/WritersEditDetails';
import WriterEditPreview from '../WritersEditPreview/WritersEditPreview';
import { useDispatch, useSelector } from 'react-redux';
import { initializeEditingState, resetEditingProcess } from '../../store/editingSlice';

function WritersEditScreen() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const editingPiece = useSelector((state) => state.editing); // Full editing state
  const { pieceHash, currentStage } = editingPiece;

  useEffect(() => {
    console.log('Editinging Piece:', editingPiece); // Debug state
    console.log('Piece Hash:', pieceHash); // Debug pieceHash
    // Redirect to profile if no pieceHash is loaded in the store
    if (!pieceHash) {
      console.warn("pieceHash is null, navigating to profile");
      navigate('/writers/profile', { replace: true });
      return;
    }

    // Redirect to the current editing stage if at the base edit path
    if (!currentStage) {
      navigate('/writers/edit/coverart', { replace: true });
    }
  }, [pieceHash, currentStage, navigate]);

  return (
    <Routes>
      <Route path="coverart" element={<WriterEditImage />} />
      <Route path="writing" element={<WriterEditWriting />} />
      <Route path="details" element={<WriterEditDetails />} />
      <Route path="preview" element={<WriterEditPreview />} />
      {/* Default to the cover art stage if no match */}
      <Route path="*" element={<WriterEditImage />} />
    </Routes>
  );
}

export default WritersEditScreen;
