import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  pieceHash: null, // Key identifier for the editing piece
  currentStage: 'coverart',
  currentPhoto: null,
  title: '',
  subtitle: '',
  description: '',
  piece: '', // Full text of the piece
  taggedBooks: [],
  genre: 'blank',
  style: 'blank',
  serialDetails: {
    isSerial: false,
    firstProfileHash: null,
    partNumber: null,
    previousSerialHash: null,
  },
  changes: {}, // Tracks old and new values for modified fields
  hasChanges: false, // Tracks if any field has been changed
};

export const editingSlice = createSlice({
  name: 'editing',
  initialState,
  reducers: {
    // Sets the pieceHash
    setPieceHash: (state, action) => {
      state.pieceHash = action.payload;
    },
    setCurrentPhoto: (state, action) => {
      const newValue = action.payload;
      if (state.currentPhoto !== newValue) {
        state.changes.currentPhoto = { oldValue: state.currentPhoto, newValue };
        state.currentPhoto = newValue;
        state.hasChanges = true;
        // Ensure pieceHash persists
        state.pieceHash = state.pieceHash || null;
      }
    },    

    // Sets the current stage of the editor
    setCurrentStage: (state, action) => {
      state.currentStage = action.payload;
    },

    // Generic field setter with change tracking
    setField: (state, action) => {
      const { field, value } = action.payload;
      if (state[field] !== value) {
        // Record old and new values if the field has changed
        state.changes[field] = {
          oldValue: state[field],
          newValue: value,
        };
        state[field] = value;
        state.hasChanges = true;
      }
    },

    // Specialized setters for individual fields
    setTitle: (state, action) => {
      const newValue = action.payload;
      if (state.title !== newValue) {
        state.changes.title = {
          oldValue: state.title,
          newValue,
        };
        state.title = newValue;
        state.hasChanges = true;
      }
    },
    setSubtitle: (state, action) => {
      const newValue = action.payload;
      if (state.subtitle !== newValue) {
        state.changes.subtitle = {
          oldValue: state.subtitle,
          newValue,
        };
        state.subtitle = newValue;
        state.hasChanges = true;
      }
    },
    setDescription: (state, action) => {
      const newValue = action.payload;
      if (state.description !== newValue) {
        state.changes.description = {
          oldValue: state.description,
          newValue,
        };
        state.description = newValue;
        state.hasChanges = true;
      }
    },
    setPiece: (state, action) => {
      const newValue = action.payload;
      if (state.piece !== newValue) {
        state.changes.piece = {
          oldValue: state.piece,
          newValue,
        };
        state.piece = newValue;
        state.hasChanges = true;
      }
    },
    setTaggedBooks: (state, action) => {
      const newValue = action.payload;
      if (JSON.stringify(state.taggedBooks) !== JSON.stringify(newValue)) {
        state.changes.taggedBooks = {
          oldValue: [...state.taggedBooks],
          newValue,
        };
        state.taggedBooks = newValue;
        state.hasChanges = true;
      }
    },
    setGenre: (state, action) => {
      const newValue = action.payload;
      if (state.genre !== newValue) {
        state.changes.genre = {
          oldValue: state.genre,
          newValue,
        };
        state.genre = newValue;
        state.hasChanges = true;
      }
    },
    setStyle: (state, action) => {
      const newValue = action.payload;
      if (state.style !== newValue) {
        state.changes.style = {
          oldValue: state.style,
          newValue,
        };
        state.style = newValue;
        state.hasChanges = true;
      }
    },

    // Initializes the editing state from a provided piece
    initializeEditingState: (state, action) => {
      console.log("initializeEditingState called", action.payload);
      return { 
        ...initialState, 
        ...action.payload, 
      };
    },

    // Resets the editing state to the initial state
    resetEditingProcess: (state) => {
      console.log("resetEditingProcess called", state);
      return initialState;
    },
    

    // Marks the state as saved, clearing change tracking
    markAsSaved: (state) => {
      state.changes = {};
      state.hasChanges = false;
    },
  },
});

export const {
  setPieceHash,
  setCurrentStage,
  setCurrentPhoto,
  setField,
  setTitle,
  setSubtitle,
  setDescription,
  setPiece,
  setTaggedBooks,
  setGenre,
  setStyle,
  initializeEditingState,
  resetEditingProcess,
  markAsSaved,
} = editingSlice.actions;

export default editingSlice.reducer;
