import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { setStyle, setGenre } from '../../store/editingSlice';
import { hideGenericModal } from '../../store/modalSlice';
import './EditDetailsEditModal.css';

const EditDetailsModal = () => {
  const dispatch = useDispatch();
  const currentStyle = useSelector((state) => state.editing.style);
  const currentGenre = useSelector((state) => state.editing .genre);
  
  const [selectedStyle, setSelectedStyle] = useState(currentStyle);
  const [selectedGenre, setSelectedGenre] = useState(currentGenre);
  const [isStyleSelected, setIsStyleSelected] = useState(true);
  
  const handleStylePress = () => {
    setIsStyleSelected(true);
  };
  
  const handleGenrePress = () => {
    setIsStyleSelected(false);
  };

  const genres = [
    "Fictional Autobiography", 
    "Creative Non-fiction",
    "Creative Fiction",
    "Memoir", 
    "Essay", 
    "Science-Fiction", 
    "Crime", 
    "Noir",
    "Journalism",
    "Erotica", 
    "Fantasy", 
    "Queer",
    "Horror", 
    "Romance", 
    "Thriller", 
    "Humor",
    "Reportage",
    "Activism and Social Change",
  ];
  
  const styleOptions = [
    "Poetry", 
    "Prose", 
    "Review", 
    "Scene", 
    "Dialogue", 
    "Excerpt", 
    "Blurb", 
    "Chapter", 
    "Character study",
    "Verse", 
  ];

  const handleConfirm = () => {
    dispatch(setStyle(selectedStyle));
    dispatch(setGenre(selectedGenre));
    dispatch(hideGenericModal());
  };

  return (
    <div className="set-style-genre-modal">
      <div className="modal-content">
        <div className="style-genre-header">
          <button className="style-genre-close-button" onClick={() => dispatch(hideGenericModal())}>
            Close
          </button>
          <button className="style-genre-confirm-button" onClick={handleConfirm}>
            Confirm
          </button>
        </div>
        <div className="style-genre-selector-container">
          <button onClick={handleStylePress} className={`style-genre-selector ${isStyleSelected ? 'style-genre-selected-top' : ''}`}>
            Style
          </button>
          <button onClick={handleGenrePress} className={`style-genre-selector ${!isStyleSelected ? 'style-genre-selected-top' : ''}`}>
            Genre
          </button>
        </div>
        <div className="style-genre-options-container">
          {isStyleSelected
            ? styleOptions.map((style, index) => (
                <div
                  key={index}
                  className={`style-genre-option-item ${selectedStyle === style ? 'style-genre-selected' : ''}`}
                  onClick={() => setSelectedStyle(style)}
                >
                  {style}
                </div>
              ))
            : genres.map((genre, index) => (
                <div
                  key={index}
                  className={`style-genre-option-item ${selectedGenre === genre ? 'style-genre-selected' : ''}`}
                  onClick={() => setSelectedGenre(genre)}
                >
                  {genre}
                </div>
              ))}
        </div>
      </div>
    </div>
  );
};

export default EditDetailsModal;
