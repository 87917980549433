// WritersEditImage.js
import React, { useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { setCurrentPhoto, setCurrentStage, resetEditingProcess } from '../../store/editingSlice';
import defaultImage from '../../assets/images/birdie.jpeg';
import HeaderEdit from '../../components/HeaderEdit/HeaderEdit';
import { apiInstance } from '../../utils/axiosInstance';
import { fetchAuthSession } from 'aws-amplify/auth';
import './WritersEditImage.css';

function WritersEditImage() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const editingPiece = useSelector((state) => state.editing); // Full editing state
  const { pieceHash, currentStage } = editingPiece;

  const currentPhoto = useSelector(state => state.editing.currentPhoto) || defaultImage;
  const fileInputRef = useRef(null);
  const [isDefault, setIsDefault] = useState(currentPhoto === defaultImage);
  const [isUploading, setIsUploading] = useState(false);

  const buttonText = "Use image and continue to next step →";
  const otherButtonText = "Discard editing";

  const handleImageUploadClick = () => {
    fileInputRef.current.click();
  };

  const handleImageUpload = async (event) => {
    const file = event.target.files[0];
    if (!file) return;
  
    console.log('Starting upload for:', file.name);
    setIsUploading(true);
  
    try {
      const session = await fetchAuthSession();
      console.log('Session fetched successfully');
      const jwtToken = session.tokens.idToken.toString();
  
      const formData = new FormData();
      formData.append("content_type", file.type);
      formData.append("file_size", file.size);
      formData.append("original_filename", file.name);
  
      const response = await apiInstance.post('/pieces/generate_presigned_url', formData, {
        headers: {
          Authorization: `Bearer ${jwtToken}`,
        },
      });
  
      if (response.status !== 200) throw new Error('Failed to fetch presigned URL');
      console.log('Presigned URL fetched:', response.data);
  
      const { presigned_url, file_key } = response.data;
      await fetch(presigned_url, {
        method: 'PUT',
        body: file,
        headers: { 'Content-Type': file.type },
      });
  
      const S3_BUCKET_URL = "https://shrtrstrz-coverart-dev.s3.amazonaws.com/";
      const fullFileUrl = `${S3_BUCKET_URL}${file_key}`;
      console.log('Image uploaded successfully to:', fullFileUrl);
  
      dispatch(setCurrentPhoto(fullFileUrl));
      setIsDefault(false);
    } catch (error) {
      console.error('Error during upload:', error);
    } finally {
      setIsUploading(false);
      console.log('Upload state reset: ',pieceHash);

    }
  };
  

  const discardImage = () => {
    dispatch(setCurrentPhoto(null));
    setIsDefault(true);
  };

  const navigateToNextStage = () => {
    dispatch(setCurrentStage('writing'));
    navigate(`/writers/edit/writing`);
  };

  const handleImageLoad = (e) => {
    const img = e.target;
    if (img.naturalWidth < img.naturalHeight) {
      img.style.width = '100%';
      img.style.height = `${img.offsetWidth}px`;
      img.style.objectFit = 'cover';
    } else {
      img.style.width = '100%';
      img.style.height = 'auto';
    }
  };

  const handleDiscardAndRestart = () => {
    const confirmRestart = window.confirm("Are you sure you want to discard your edits?");
    if (confirmRestart) {
      dispatch(resetEditingProcess());
      navigate('/writers/edit/selection');
    }
  };

  return (
    <div className="modalesque">
      <HeaderEdit />
      <div className="contentBoxes">
        <div className="titleContainerey">
          <p className="modalTitley">Upload some cover art:</p>
        </div>
        <div>
          {isUploading ? (
            <div className="overlay-spinner">
              <div className="spinner"></div>
              <p>Uploading your image, please wait...</p>
            </div>
          ) : (
            <img 
              src={currentPhoto} 
              alt="Cover Art" 
              className="image-previews" 
              onLoad={handleImageLoad} 
            />
          )}
        </div>
        <div className="fileUploadContainer">
          <input 
            type="file" 
            ref={fileInputRef} 
            onChange={handleImageUpload} 
            className="fileInput" 
            accept="image/*" 
            style={{ display: 'none' }} 
          />
          {!isDefault && (
            <div className="buttonRow">
              <button className="infosButton" onClick={discardImage}>Discard image</button>
              <button className="infosButton" onClick={handleImageUploadClick}>Upload new cover image</button>
            </div>
          )}
          {isDefault && (
            <button className="infosButton" onClick={handleImageUploadClick}>Upload cover image</button>
          )}
        </div>
        <button 
          onClick={navigateToNextStage} 
          className="infosButton" 
          style={{ fontWeight: 'bold' }}
        >
          {buttonText}
        </button>
        <div className="button-separatores"></div>
        <button 
          onClick={handleDiscardAndRestart} 
          className="infosButton"
        >
          {otherButtonText}
        </button>
      </div>
    </div>
  );
}

export default WritersEditImage;
