import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  chosenBook: null,
  chosenFormat: null,
  chosenEdition: null,
  taggedBooks: [],
  formatOptions: [],
  booksOnSale: { books_on_sale: [] },
  bnBooksOnSale: [],
  selectedBookOnSale: null,
  selectedBookOnSaleBN: null,
  chosenRetailer: null,
  chosenAuthor: null,
  restrictedRetailer: null,
};

const taggingEditSlice = createSlice({
  name: 'taggingEdit',
  initialState,
  reducers: {
    setChosenBook(state, action) {
      state.chosenBook = action.payload;
    },
    setChosenFormat(state, action) {
      state.chosenFormat = action.payload;
    },
    setChosenEdition(state, action) {
      state.chosenEdition = action.payload;
    },
    setChosenRetailer(state, action) {
      state.chosenRetailer = action.payload;
    },
    setChosenAuthor(state, action) {
      state.chosenAuthor = action.payload;
    },
    setRestrictedRetailer(state, action) {
      state.restrictedRetailer = action.payload;
    },
    clearSelections(state) {
      state.chosenBook = null;
      state.chosenFormat = null;
      state.chosenEdition = null;
      state.chosenAuthor = null;
      state.taggedBooks = [];
      state.formatOptions = [];
      state.booksOnSale = { books_on_sale: [] };
      state.bnBooksOnSale = [];
      state.selectedBookOnSale = null;
      state.selectedBookOnSaleBN = null;
      state.restrictedRetailer = null;
    },
    addTaggedBook(state, action) {
      state.taggedBooks.push(action.payload);
    },
    setFormatOptions(state, action) {
      state.formatOptions = action.payload;
    },
    setBooksOnSale(state, action) {
      state.booksOnSale = action.payload;
    },
    setBnBooksOnSale(state, action) {
      state.bnBooksOnSale = action.payload;
    },
    clearBnBooksOnSale(state) {
      state.bnBooksOnSale = [];
    },
    setSelectedBookOnSale(state, action) {
      state.selectedBookOnSale = action.payload;
    },
    setSelectedBookOnSaleBN(state, action) {
      state.selectedBookOnSaleBN = action.payload;
    },
  },
});

export const {
  setChosenBook,
  setChosenFormat,
  setChosenEdition,
  setChosenAuthor,
  clearSelections,
  setRestrictedRetailer,
  addTaggedBook,
  setFormatOptions,
  setBooksOnSale,
  setBnBooksOnSale,
  clearBnBooksOnSale,
  setSelectedBookOnSale,
  setSelectedBookOnSaleBN,
  setChosenRetailer,
} = taggingEditSlice.actions;

export default taggingEditSlice.reducer;
