import React, { useState, useEffect } from 'react';
import './EnhancedBookEditCard.css'; // Import your book card styles
import { useSelector, useDispatch } from 'react-redux';
import failedbooks from '../../assets/images/book.png';
import { capitalizeFirstLetterOfEachWord } from '../../utils/capitalize';

const EnhancedBookCard = ({ isBarnesAndNoble = false }) => {
    const selectedBookOnSale = useSelector((state) => 
      isBarnesAndNoble ? state.taggingEdit.selectedBookOnSaleBN : state.taggingEdit.selectedBookOnSale
    );
    const [imageLoadedSuccessfully, setImageLoadedSuccessfully] = useState(false);
    console.log(selectedBookOnSale, 'selected books');

    console.log('heeeellllooo');


    const RetryableImage = ({ src, alt, className, maxRetries = 10, interval = 1000 }) => {
      const [retryCount, setRetryCount] = useState(0);
      
      const [imageSrc, setImageSrc] = useState(failedbooks); // Initially set to fallback image
    
      useEffect(() => {
        const img = new Image();
        img.onload = () => {

          setImageLoadedSuccessfully(true);
          setImageSrc(src); // Only set to the original source on successful load
        };
        img.onerror = () => {
          if (retryCount < maxRetries) {
            setTimeout(() => {
              console.log(`Retrying image load: Attempt ${retryCount + 1}`);
              setRetryCount(retryCount + 1);
              img.src = `${src}?retry=${retryCount + 1}`; // Attempt to reload with retry query
            }, interval);
          } else {
            setImageSrc(failedbooks); // Keep the fallback image if max retries are exceeded
          }
        };
        img.src = `${src}?retry=${retryCount}`; // Start loading with retry count to bypass cache
        // Set the source to the fallback image every time the component attempts to load the original image
        setImageSrc(failedbooks);
    
        return () => {
          img.onload = null;
          img.onerror = null;
        };
      }, [retryCount, maxRetries, interval]);
    
      // Always render the img element with the current source
      return <img src={imageSrc} alt={alt} className={className} />;
    };

    const capitalizedTitle = (isBarnesAndNoble ? selectedBookOnSale.full_title : selectedBookOnSale.raw_title) 
      ? capitalizeFirstLetterOfEachWord(isBarnesAndNoble ? selectedBookOnSale.full_title : selectedBookOnSale.raw_title )
      : '';

  const capitalizedAuthor = selectedBookOnSale.author 
      ? capitalizeFirstLetterOfEachWord(selectedBookOnSale.author) 
      : '';

    return (
      <div className="enhanced-book-card">
        {imageLoadedSuccessfully ? (
          <img
            className="books-image"
            src={selectedBookOnSale.image_link}
            alt={isBarnesAndNoble ? selectedBookOnSale.full_title : selectedBookOnSale.raw_title}
          />
        ) : (
          <RetryableImage
            className="books-image"
            src={selectedBookOnSale.image_link}
            alt={isBarnesAndNoble ? selectedBookOnSale.full_title : selectedBookOnSale.raw_title}
            maxRetries={10} // Customize as needed
            interval={1000} // Interval in milliseconds
            imageLoaded={imageLoadedSuccessfully}
            setImageLoaded={setImageLoadedSuccessfully}
          />
        )}
        <div className="books-info">
          <p><strong>{capitalizedTitle}</strong> by {capitalizedAuthor}</p>
          {!isBarnesAndNoble && selectedBookOnSale.internal_genre && selectedBookOnSale.primary_category && (
            <p>{selectedBookOnSale.internal_genre} - {selectedBookOnSale.primary_category}</p>
          )}

        </div>
      </div>
    );

  };
  
  export default EnhancedBookCard;