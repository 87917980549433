import React, { useState, useEffect }  from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import './HeaderPublishPreview.css';
import { useSelector, useDispatch } from 'react-redux';
import { isEqual } from 'lodash';
import { useMutation, useQueryClient } from 'react-query';
import { fetchAuthSession } from 'aws-amplify/auth';
import { apiInstance } from '../../utils/axiosInstance'; 
import {resetPublishingProcess} from '../../store/publishingSlice';
import {clearSelections} from '../../store/taggingSlice';

import defaultImage from '../../assets/images/birdie.jpeg';



function HeaderPublishPreview() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const queryClient = useQueryClient();
  
  const [hasChanges, setHasChanges] = useState(false);
  const userId = useSelector(state => state.user.userId);
  const userName = useSelector(state => state.user.userName);
  const currentPhoto = useSelector(state => state.publishing.currentPhoto) || defaultImage;
  const { data: authorProfile } = useSelector((state) => state.authorProfile);
  const [isPublishing, setIsPublishing] = useState(false);

  const {
    title,
    piece,
    taggedBooks,
    description,

    genre,
    style,
    isSerial,
    serialFirstProfileHash,
    serialPartNumber,
    previousSerialHash

  } = useSelector(state => state.publishing);

  console.log('title', taggedBooks);
  console.log('authorProfile', authorProfile);
  console.log('userName', userName);
  console.log('userId', userId);

  function stripHtml(html) {
    // Create a new div element
    const temporalDivElement = document.createElement("div");
    // Set the HTML content with the provided
    temporalDivElement.innerHTML = html;
    // Retrieve the text property of the element (cross-browser support)
    return temporalDivElement.textContent || temporalDivElement.innerText || "";
  }

  function countWords(text) {
    // Use regular expression to replace multiple spaces with a single space and trim spaces
    return text.replace(/\s+/g, ' ').trim().split(' ').length;
  }

  const wordCount = countWords(stripHtml(piece));


  const { mutate: publishPiece, isLoading, isError } = useMutation(async (formData) => {
    const session = await fetchAuthSession();
    const jwtToken = session.tokens.idToken.toString();

    console.log('formData', formData);

    const response = await apiInstance.post('/pieces/create_two', formData, {
        headers: {
            'Authorization': `Bearer ${jwtToken}`,
            'Content-Type': 'multipart/form-data',
        },
    });

    return response.data;
}, {
    onSuccess: (data) => {
        console.log('Piece published successfully:', data);
        queryClient.invalidateQueries(['pieces']);
        queryClient.refetchQueries(['pieces']);
        queryClient.invalidateQueries(['profileFeed', userId, userName]);
        queryClient.refetchQueries(['profileFeed', userId, userName]); 
        console.log('we invalidating', userId, userName);
        dispatch(clearSelections()); // Assuming you have imported the slice as taggingSlice
        dispatch(resetPublishingProcess());
        navigate('/writers');
        setIsPublishing(false);
    },
    onError: (error) => {
        console.error('Error publishing piece:', error);
        alert('Failed to publish the piece. Please try again.');
        alert(error);
        setIsPublishing(false);
        
    },
});

  const navigateToProfile = () => {
    navigate('/writers/publish/details'); // Assuming '/profile' is the route for the profile page
  };

  const saveChanges = async () => {
    try {
      setIsPublishing(true);
  
      // Build the payload
      const payload = {
        title,
        piece_content: piece,
        piece_genre: genre,
        piece_style: style,
        taggedBooks: taggedBooks,
        userId,
        profile_hash: authorProfile.profile_hash,
        author_name: authorProfile.name,
        publishing_user: userId,
        description,
        piece_length: wordCount,
        show_in_the_feed: authorProfile.profile_hash !== 'AcS1Na49',
        is_serial: isSerial,
        serial_first_profile_hash: serialFirstProfileHash,
        serial_part_number: serialPartNumber,
        previous_serial_hash: previousSerialHash,
        image_key: currentPhoto !== defaultImage ? currentPhoto : null, // Include the S3 key if uploaded
      };
  
      console.log('Payload:', payload);
  
      // Call the mutation
      await publishPiece(payload);
    } catch (error) {
      console.error('Error in saveChanges:', error);
      alert(`Error saving piece: ${error.message || error}`);
      setIsPublishing(false);
    }
  };
  



  return (
    <div className="header-preview-piece-container"> {/* New container div */}
      <div className="header-preview-piece">
        <span className="return-to-edit" onClick={navigateToProfile}>Return to edit</span>
        <span 
          className={`publish-piece ${isPublishing ? 'disabled' : ''}`} 
          onClick={!isPublishing ? saveChanges : undefined}
        >
          {isPublishing ? 'Publishing...' : 'Publish piece'}
        </span>
      </div>
    </div>
  );

}

export default HeaderPublishPreview;