import React, { useState, useEffect } from 'react';
import './TagBookEditModalTwo.css'; // Ensure CSS is structured similarly to EditMajorGenreModal.css
import { useSelector, useDispatch } from 'react-redux';
import { setChosenBook, setChosenFormat, setBooksOnSale, setSelectedBookOnSale} from '../../store/taggingEditSlice';
import { hideGenericModal, showGenericModal } from '../../store/modalSlice';
import Select from 'react-select';
import { fetchAuthSession } from 'aws-amplify/auth';

import { apiInstance } from '../../utils/axiosInstance';
import failedbooks from '../../assets/images/book.png';


const TagBookModal = () => {
  const dispatch = useDispatch();
  const formatOptions = useSelector((state) => state.taggingEdit.formatOptions);
  const chosenFormat = useSelector((state) => state.taggingEdit.chosenFormat);
  const booksOnSale = useSelector((state) => state.taggingEdit.booksOnSale);;
  const chosenBook = useSelector((state) => state.taggingEdit.chosenBook);;
  const selectedBookOnSale = useSelector((state) => state.taggingEdit.selectedBookOnSale);

  console.log('Chosen format:', chosenFormat);

  


  console.log('Books on sale:', booksOnSale);

  const nextModal = (nextModalType, nextModalProps = {}) => {
    dispatch(hideGenericModal()); // Hide the current modal
    dispatch(showGenericModal({
      modalType: nextModalType,
      modalProps: nextModalProps
    })); // Immediately show the next modal
  };

  const getJwtToken = async () => {
    const session = await fetchAuthSession();
    const jwtToken = session.tokens.idToken.toString();
    return jwtToken;
  };

  

  
  const RetryableImage = ({ src, alt, className, maxRetries = 15, interval = 1000 }) => {
    const [retryCount, setRetryCount] = useState(0);
    const [imageSrc, setImageSrc] = useState(failedbooks); // Initially set to fallback image
  
    useEffect(() => {
      const img = new Image();
      img.onload = () => {

        setImageSrc(src); // Only set to the original source on successful load
      };
      img.onerror = () => {
        if (retryCount < maxRetries) {
          setTimeout(() => {
            console.log(`Retrying image load: Attempt ${retryCount + 1}`);
            setRetryCount(retryCount + 1);
            img.src = `${src}?retry=${retryCount + 1}`; // Attempt to reload with retry query
          }, interval);
        } else {
          setImageSrc(failedbooks); // Keep the fallback image if max retries are exceeded
        }
      };
      img.src = `${src}?retry=${retryCount}`; // Start loading with retry count to bypass cache
      // Set the source to the fallback image every time the component attempts to load the original image
      setImageSrc(failedbooks);
  
      return () => {
        img.onload = null;
        img.onerror = null;
      };
    }, [src, retryCount, maxRetries, interval]);
  
    // Always render the img element with the current source
    return <img src={imageSrc} alt={alt} className={className} />;
  };


  const customStyles = {
    menuPortal: base => ({ ...base, zIndex: 9999 }), // Adjust zIndex as needed to ensure it's above your modal
  };

  const handleChangeFormat = async (option) => {
    dispatch(setChosenFormat(option || null)); // Update the chosen format in state
    dispatch(setBooksOnSale({books_on_sale:[]})); 

    
    

    if (!option) {
      dispatch(setBooksOnSale({books_on_sale:[]})); // Clear books if no format is chosen
      return;
    }

    // Fetch new books based on the chosen format
    const jwtToken = await getJwtToken();
    try {
      const response = await apiInstance.get(`affiliate_tables/books_for_sale`, {
        params: { format: option.value, author: chosenBook.author, raw_title: chosenBook.raw_title },
        headers: { 'Authorization': `Bearer ${jwtToken}` },
      });
      dispatch(setBooksOnSale(response.data)); // Assuming the API returns an array of books
    } catch (error) {
      console.error('Error fetching books on sale:', error);
      dispatch(setBooksOnSale({books_on_sale:[]})); // Clear the book list on error
    }
  };

  const handleSelectBookOnSale = (book) => {
    dispatch(setSelectedBookOnSale(book));
  };

  const prevModal = () => {
    dispatch(hideGenericModal());
    dispatch(showGenericModal({
      modalType: 'TAG_BOOK_ONE_AND_HALF_EDIT',
    }));
  };
  


  return (
    <div className="modalBackground">
      <div className="modalesContaineres">
        <h2 className="modalsTitles">Tagging a book:</h2>
        <div className="tags-dividers"/>
        <label className="sectionTitle">3. Choose the edition/publisher/price/format:</label>
        <div className="horizontal-feed-background">
          <div className="horizontal-feed">
            {booksOnSale.items && booksOnSale.items.map((book, index) => (
                <div
                  key={index}
                  className={`book-item ${selectedBookOnSale && selectedBookOnSale.external_product_id === book.external_product_id ? 'selected-book-item' : ''}`}
                  onClick={() => handleSelectBookOnSale(book)}
                >
                  <RetryableImage
                    src={book.image_link}
                    alt="Cover"
                    className="books-images"
                  />
                  <div className="book-details">
                    <div>{book.publisher}</div>
                    <div>{book.local_format}</div>
                    <div>${book.price_amount}</div>
                    
                    <div>
                      <a 
                        href={book.link} 
                        target="_blank" 
                        rel="noopener noreferrer" 
                        style={{ color: 'black', textDecoration: 'underline' }}
                      >
                        check link
                      </a>
                    </div>
                  </div>
                </div>
              ))}

          </div>
        </div>
        <button 
          className="infosButton-details-next"  
          onClick={() => nextModal('TAG_BOOK_THREE_EDIT', { /* next modal props */ })}
          disabled={!selectedBookOnSale}
        >
          Next step &nbsp;&nbsp;&#8209;&#8209;&#8209;&gt;&nbsp;
        </button>
        <button 
          className="infosButton-details-prev" 
          onClick={prevModal}
        >
          &lt;--- Previous step
        </button>
      </div>
    </div>
  );
};

export default TagBookModal;
