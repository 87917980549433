import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import { fetchAuthorProfile } from '../../store/authorProfileSlice';
import { useQuery } from 'react-query';
import { apiInstance } from '../../utils/axiosInstance';
import Header from '../../components/Header/Header';
import ProfileFeed from '../../components/ProfileFeed/ProfileFeed';
import AuthorSummary from '../../components/WriterAuthorSummary/WriterAuthorSummary';

import './WritersProfileScreen.css';
 
const WritersProfileScreen = ({ authorInfo }) => {
    const { profileHash } = useParams();
    const userId = useSelector((state) => state.user.userId);
    const userName = useSelector((state) => state.user.userName);
    const dispatch = useDispatch();
    const { data: authorProfile, isLoading: isLoadingProfile, error: errorProfile } = useSelector((state) => state.authorProfile);
    // Fetch author profile data
    useEffect(() => {
        if (!authorProfile && !isLoadingProfile) {
          // Trigger the fetch only if authorProfile hasn't been loaded and not currently loading
          dispatch(fetchAuthorProfile({ userId, userName }));
        }
        // It's essential to include isLoading in the dependency array if its value is used in the condition
      }, [dispatch, userId, userName, authorProfile, isLoadingProfile]);

    // Fetch profile feed data
    const { data: pieces, isLoading: isLoadingFeed, error: errorFeed } = useQuery(
        ['profileFeed', userId, userName], 
        async () => {
            const response = await apiInstance.get(`/pieces/profilefeed/fetch?user_id=${userId}&author_name=${encodeURIComponent(userName)}`);

            console.log('Fetchedness profile feed:', response.data.feed_data);
            return response.data.feed_data;
        }
    );

    if (isLoadingProfile || isLoadingFeed) return <div>Loading...</div>;
    if (errorProfile || errorFeed) return <div>Error fetching data.</div>;

    return (
        <div className="profilePage">
            <Header />
            {authorProfile && <AuthorSummary summary={authorProfile} />}
            {authorProfile && pieces.length > 0 && (
                <ProfileFeed pieces={pieces} authorProfile={authorProfile} />
            )}
        </div>
    );
};

export default WritersProfileScreen;
